import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import axios from 'axios'
//import * as Yup from "yup";

class InvoiceAddress extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        name: "",
        country_code: "",
        zip: "",
        state: "",
        city: "",
        floor: "",
        street: "",
        street_number: "",
        tax: "",
        taxType: ""
    };
  }
  getRegionByZip = async (zip) => {
    let response = await axios(this.props.endpoint+'/api/v1/address/regionByZip/'+zip)
    return(response.data)
  }
  setAddressByZip = async (zip) => {
    if(zip.length < 5) {
        if(zip.length === 4) {
            let addressInfo = await this.getRegionByZip(zip)
            await this.setState({
              zip: zip,
              state: addressInfo.state !== undefined ? addressInfo.state : "",
              city: addressInfo.region !== undefined ? addressInfo.region + (addressInfo.sub_region !== "" ? ", " + addressInfo.sub_region : "") : "",
            });
            this.props.onChange(({target: {name: this.props.name, value: JSON.stringify(this.state)}}))
            
        } else {
            await this.setState({ 
              zip: zip,
              state: "",
              city: ""
            })
        }
    }        
  }
  onChangeValue = async (e) => {
    if(e.target !== undefined) {
      let name = e.target.name
      let value = e.target.value

      if(name === "zip") {
        this.setAddressByZip(value)
      }
      await this.setState({ [e.name]: e.value });
      this.props.onChange(({target: {name: this.props.name, value: JSON.stringify(this.state)}}))
    }
  };
  sortPropsToState = () => {
    if(this.props.value === undefined || this.props.value === "" || this.props.value === "null") return null
    for (let [key, value ] of Object.entries(JSON.parse(this.props.value))) {
      this.setState({ [key]: value });
    }
  }

  componentDidMount = () => {
    this.sortPropsToState()
  }

  render() {
    return (
      <React.Fragment>
         <TextField
            id="invoiceName"
            className="name"
            name="invoiceName"
            label={this.props.dictionary['app_order_nameOrCompany'].translation[this.props.selectedLanguage]}
            value={this.state.name}
            error={false}
            helperText={""}
            onChange={this.props.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}   
        />
        <TextField
            id="zip"
            className="zip"
            name="zip"
            label={this.props.dictionary['app_order_zip'].translation[this.props.selectedLanguage]}
            value={this.state.zip}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            type="number"
            variant="filled"
            hidden={this.props.hidden}
        />
        <TextField
            id="city"
            className="city"
            name="city"
            label={this.props.dictionary['app_order_city'].translation[this.props.selectedLanguage]}
            value={this.state.city}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}
        />             
        <TextField
            id="state"
            className="state"
            name="state"
            label={"Megye"}
            value={this.state.state}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}
        />           
        <TextField
            id="street"
            className="street"
            name="street"
            label={"Utca"}
            value={this.state.street}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}
        />
        <TextField
            id="streetNumber"
            className="streetNumber"
            name="street_number"
            label={"Házszám"}
            value={this.state.street_number}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}
        />
        <TextField
            id="floor"
            className="floor"
            name="floor"
            label={"Emelet, ajtó"}
            value={this.state.floor}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
            hidden={this.props.hidden}
        />                
      </React.Fragment>
    );
  }
}

export default InvoiceAddress;
