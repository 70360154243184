import React, { Component } from "react"
import Button from '@material-ui/core/Button'
import { Formik, Field } from "formik"
import * as Yup from "yup"
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import { toast } from 'react-toastify'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import Address from "./Address"
import merge from 'lodash.merge'

const basicSchema = Yup.object().shape({
    first_name: Yup.string()
        .max(50, "Túl hosszú!")
        .required("Név kötelező"),
    last_name: Yup.string()
        .max(50, "Túl hosszú!")
        .required("Név kötelező"),
    telNumber: Yup.string()
        .min(2, "Túl rövid!")
        .max(50, "Túl hosszú!")
        .matches(/^[0-9]*$/, {
            message: "Helytelen formátum, csak számot tartalmazhat."
    }),
})

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            birthdate: "0000-00-00",
        };
    }
    changeUserData = async (data) => {
        let response = await axios(this.props.endpoint+'/api/v1/user', {      
          method: 'post',
          data: JSON.stringify(data)
        })
        this.props.loginWithToken()
        return response.data
    }
    notifySuccess = () => toast.success("Adatok mentve!")
    notifyFail = () => toast.error("Sikertelen mentés!")
    handleDateChange = (e) => {
        this.setState({
          birthdate: e
        })
    }
    onSubmitForm = async (formValues) => {

        formValues.country_code = 'HU'

        let address = {
            name: "",
            country_code: "HU",
            zip: "-",
            state: "-",
            city: "-",
            floor: "-",
            street: "-",
            street_number: "-",
            tax: "-",
            taxType: "-"
        }


        try {

            address = merge(address, JSON.parse(formValues.address))

        } catch(e) {
            console.log(e)
        }

        let userData = {
            first_name: formValues.first_name !== undefined ? formValues.first_name : "",
            last_name: formValues.last_name !== undefined ? formValues.last_name : "",
            tel_number: formValues.telNumber !== undefined ? formValues.telNumber : "",
            birthdate: moment(this.state.birthdate).format("YYYY-MM-DD"), 
            address
        }

        console.log(userData)
        //console.log(userData)
        let response = await this.changeUserData(userData)
        if(response.success) {
            this.notifySuccess()
        } else {
            this.notifyFail()
        }
    }
    validateAddress = (value) => {
        let error
        if(value !== "null") {
            let values = JSON.parse(value)
            if(values.zipHelperText !== "") error += values.zipHelperText + " "
            if(values.stateHelperText !== "") error += values.stateHelperText + " "
            if(values.streetHelperText !== "") error += values.streetHelperText + " "
            if(values.streetNumberHelperText !== "") error += values.streetNumberHelperText + " "

            if(values.zip === "") error += "irányítószám hiányzik "
            if(values.state === "") error += "megye hiányzik "
            if(values.street === "") error += "utca hiányzik "
            if(values.streetNumber === "") error += "házszám hiányzik "
            
            //console.log(error)
        }
        return error
    }
    renderErrors = (e) => { 
        if(e !== undefined) {
            let errorString = ""
            Object.keys(e).forEach(function (item) {
                errorString += e[item] + ", "
            });
            errorString = errorString.substring(0, errorString.length-2)
            if(errorString !== "") {
                return (<div className="formErrorContainer hidden appear">{errorString}</div>)
            } else return null
        }
    }
    componentDidMount() {
        let userData = localStorage['userData'] !== undefined ? JSON.parse(localStorage['userData']) : {}
        this.setState({
            birthdate: userData && userData !== null && userData.birthdate !== undefined ? new Date(userData.birthdate) : "0000-00-00"
        })
    }
    render() {
        let userData = localStorage['userData'] !== undefined ? JSON.parse(localStorage['userData']) : {}
        return (
            <div className="profileContainer">
                <Formik
                    initialValues={{ 
                        first_name: userData.first_name !== undefined ? userData.first_name : "", 
                        last_name: userData.last_name !== undefined ? userData.last_name : "", 
                        email: userData.email !== undefined ? userData.email : "", 
                        telNumber: userData.tel_number !== undefined ? userData.tel_number : "", 
                        address: userData.address !== undefined ? JSON.stringify(userData.address) : "{}"
                    }} 
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.onSubmitForm(values)
                            setSubmitting(false);
                        }, 400);
                    }}
                    validationSchema={basicSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="userDataContainer">
                                <TextField
                                    id="last_name"
                                    className="lastName"
                                    name="last_name"
                                    label={"Vezetéknév"}
                                    value={values.last_name}
                                    error={errors.last_name && touched.last_name}
                                    helperText={errors.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    variant="filled"
                                />
                                <TextField
                                    id="first_name"
                                    className="firstName"
                                    name="first_name"
                                    label={"Keresztnév"}
                                    value={values.first_name}
                                    error={errors.first_name && touched.first_name}
                                    helperText={errors.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    variant="filled"
                                />
                                <TextField
                                    id="email"
                                    className="email"
                                    name="email"
                                    label={"Email cím"}
                                    value={values.email}
                                    error={errors.email && touched.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    disabled
                                    variant="filled"
                                />
                                <div className="birthdate MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">
                                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-filled MuiFormLabel-filled">
                                        Születésnap
                                    </label>
                                    <DatePicker 
                                        value={this.state.birthdate} 
                                        onChange={this.handleDateChange} 
                                        format={"YYYY-MM-DD"}
                                    />
                                </div>
                                <TextField
                                    id="telNumber"
                                    className="phone"
                                    name="telNumber"
                                    label={"Telefonszám"}
                                    value={values.telNumber}
                                    error={errors.telNumber && touched.telNumber}
                                    helperText={errors.telNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    variant="filled"
                                />
                                <Field as={Address} 
                                    validate={this.validateAddress}
                                    name="address" 
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    dictionary={this.props.dictionary}
                                    selectedLanguage={this.props.selectedLanguage}
                                    endpoint={this.props.endpoint}  
                                />
                                {this.renderErrors()}
                                <Button className="submitOrder" variant="contained" color="primary" disabled={isSubmitting} type="submit">
                                    Adatok mentése
                                </Button>
                            </div>     
                        </form>
                    )}
                </Formik>  
            </div>
        )
    }
}
export default Profile;
