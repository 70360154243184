import React, {Component} from 'react'
import './style.scss'
import 'typeface-roboto'
import config from './config'
import {withRouter} from 'react-router-dom'
import LogRocket from 'logrocket'

import Webshop from './screens/Webshop'
import Minisite from './screens/Minisite'

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      load: null
    }
  }
  componentDidMount() {

    LogRocket.init('cnp98d/stegtihany-webshop');

    if(process.env.NODE_ENV === "development") {
      this.setState({
        load: 'webshop'
      })
    } else {
      let site = 'webshop'
      if(process.env.PUBLIC_URL === config.webshopURL) site = 'webshop'
      if(process.env.PUBLIC_URL === config.minisiteURL) site = 'minisite'
      this.setState({
        load: site
      })
    }
  }
  render() {
    if(this.state.load == null) return "Loading..." 
    if(this.state.load === 'webshop')
    return (
      <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}>
        <Webshop />
      </div>
    )
    if(this.state.load === 'minisite')
    return (
      <Minisite />
    )
  }
}

export default withRouter(App);
