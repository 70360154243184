import React, {Component} from 'react'
import MainContext from './MainContext'
import {Route, Switch, withRouter} from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import OrderedUUID from 'ordered-uuid'
import { NavLink } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Order from '../Order'
import Profile from '../UserProfile'
import TopBar from '../../components/TopBar'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import Categories from '../../components/Order/Categories'
import Footer from '../../components/Footer'
import LogRocket from 'logrocket'
import ClearCache from 'react-clear-cache'
import pack from '../../../package.json'

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  uri: "https://mngapidev.okeoke.io",
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
        "brand-id": localStorage.getItem('brandID') ? localStorage.getItem('brandID') : 'none'
      }
    })
  }
});

class Webshop extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      assets: null,
      dictionary: null,
      currencies: null,
      selectedLanguage: localStorage['selectedLanguage'] === undefined ? "hu" : localStorage['selectedLanguage'],
      languages: null,
      setSelectedLanguage: this.setSelectedLanguage,
      endpoint: config.endpoint,
      brandID: config.brandID,
      welcomePage: config.welcomePage,
      deliveryConfig: config.deliveryTypes,
      paymentConfig: config.paymentTypes,
      itemView: config.itemView,
      stockControl: config.stockControl,
      guestShopping: config.guestShopping,
      loaderColor: config.loaderColor,
      backgroundImg: config.backgroundImg,
      itemTree: null,
      itemSizes: null,
      orderItemTree: null,
      selectedLocationUUID: localStorage['selectedLocationUUID'],
      orderUUID: localStorage['orderUUID'] === undefined ? null : localStorage['orderUUID'],
      setSelectedItemDetails: this.setSelectedItemDetails,
      getItemSizeName: this.getItemSizeName,
      setOrderItemCount: this.setOrderItemCount,
      selectedItemCount: localStorage['selectedItemCount'] === undefined ? 0 : parseInt(localStorage['selectedItemCount']),
      setAvailableItems: this.setAvailableItems,
      availableItems: null,
      isItemVisible: this.isItemVisible,
      resetOrderItemtree: this.resetOrderItemtree,
      setItemsToSubmit: this.setItemsToSubmit,
      itemsToSubmit: null,
      generateUserPin: this.generateUserPin,
      validateUserPin: this.validateUserPin,
      userLoginWithCredentials: this.userLoginWithCredentials,
      loggedIn: false,
      userLoggedOut: this.userLoggedOut,
      submitOrder: this.submitOrder,
      userData: null,
      deliveryInfo: localStorage['deliveryInfo'] !== undefined ? JSON.parse(localStorage['deliveryInfo']) : null,
      setDeliveryInfo: this.setDeliveryInfo,
      deliveryType: localStorage['deliveryType'] !== undefined ? localStorage['deliveryType'] : "store",
      setDeliveryType: this.setDeliveryType,
      invoiceInfo: localStorage['invoiceInfo'] !== undefined ? JSON.parse(localStorage['invoiceInfo']) : null,
      setInvoiceInfo: this.setInvoiceInfo,
      setUserInfo: this.setUserInfo,
      showCheckout: false,
      setCheckoutVisible: this.setCheckoutVisible,
      paymentType: localStorage['paymentType'] !== undefined ? localStorage['paymentType'] : "simplePay",
      setPaymentType: this.setPaymentType,
      checkOrderPayment: this.checkOrderPayment,
      setSearchedItems: this.setSearchedItems,
      searchVisible: config.search,
      setSearchVisible: this.setSearchVisible,
      loginWithToken: this.loginWithToken,
      checkUserMinimalRequiredData: this.checkUserMinimalRequiredData,
      getItemStockLevel: this.getItemStockLevel,
      getItemNames: this.getItemNames,
      getHighlightedText: this.getHighlightedText,
      setUserPass: this.setUserPass,
      getDeliveryCost: this.getDeliveryCost,
      setDeliveryCost: this.setDeliveryCost,
      deliveryCost: 0
    }
    axios.interceptors.request.use(config => {
      config.headers['Accept'] = 'application/json'
      config.headers['Content-Type'] = 'application/json'
      config.headers['Authorization'] = 'Bearer '+localStorage['apiToken']
      return config
    })
  }
  onLoginStatusChange = value => {
    //console.log("LOGIN STATUS CHANGED TO ", value);
  }
  generateUserPin = async (username) => {

    let content = {
      email: username,
      deviceUUID: ""
    }

    let response = await axios(this.state.endpoint+'/api/v1/auth/generateUserPin', {      
      method: 'post',
      data: JSON.stringify(content)
    })
    let data = response.data
    return data.success
  }
  userLoginWithCredentials = async (username, password) => {
    let content = {
      username: username,
      password: password,
    }
    let response = await axios(this.state.endpoint+'/api/v1/auth/requestJWTToken', {      
      method: 'post',
      data: JSON.stringify(content)
    })
    let data = response.data
    if(data.success) {
      LogRocket.identify(data.email, {
        ...data
      })
      this.userLoggedIn(data)
    }
    return data
  }
  setUserPass = async (password) => {
    let content = {
      password: password,
    }
    let response = await axios(this.state.endpoint+'/api/v1/user/setPassword', {      
      method: 'post',
      data: JSON.stringify(content)
    })
    let data = response.data
    if(data.success) {
      return true
    } else return false
  }
  validateUserPin = async (username, pin) => {
    let content = {
      email: username,
      pinCode: pin,
      playerId: "",
      deviceUUID: ""
    }
    let data = {success: false}
    try {
      let response = await axios(this.state.endpoint+'/api/v1/auth/validateUserPin', {      
        method: 'post',
        data: JSON.stringify(content)
      })
       data = response.data
      if(data.success) {
        LogRocket.identify(data.email, {
          ...data
        })
        this.userLoggedIn(data)
      }
    } catch(e) {
      console.log(e)
    }
    return data
  }
  userLoggedIn = (data) => {
    if(data.token !== undefined) {
      localStorage.setItem("apiToken", data.token)
    }
    localStorage['userData'] = JSON.stringify(data.data.user)
    this.setState({
      loggedIn: true,
      userData: data.data.user
    })
  }
  userLoggedOut = () => {
    /*axios(this.state.endpoint+'/api/v1/auth/unassignUserDevice', {      
      method: 'post',
      data: {}
    })
    this.sendMessageToWebView(JSON.stringify({action: 'setToken', token: ''}))*/
    localStorage.setItem("apiToken", "")
    this.setState({
      loggedIn: false,
      userData: {}
    })
    localStorage.clear()
    window.location.reload(false);
  }
  loginWithToken = async () => {
    let response = await axios(this.state.endpoint+'/api/v1/auth/refreshJWTToken', {
      method: 'post'
    })
    let data = response.data
    if(data.success) {
      LogRocket.identify(data.email, {
        ...data
      })
      this.userLoggedIn(data)
    }
  }
  checkUserMinimalRequiredData = () => {
    let userData = this.state.userData
    if(userData !== null) {
      return(
        userData.first_name !== undefined && userData.first_name !== "" &&
        userData.last_name !== undefined && userData.last_name !== ""
      )
    } else return null
  }
  setSearchVisible = (e) => {
    this.setState({
      searchVisible: e
    })
  }
  isItemVisible = (uuid) => {
    if(localStorage['selectedLocationUUID'] === undefined || localStorage['selectedLocationUUID'] === 'null') return true
    else {
      let response = false;
      this.state.availableItems.forEach(function(item) {
        if(item.item_uuid === uuid) {        
          response = (item.available && item.visible)
        }
      });
      return response;
    }
  }
  getItemStockLevel = (uuid) => {
    let response = false;
    if(this.state.stockControl) {
      this.state.availableItems.forEach(function(item) {
        if(item.item_uuid === uuid) {        
          response = (item.count)
        }
      });
    } else response = 999999
    return response
  }
  getItemNames = () => {
    let itemNames = []
    this.state.orderItemTree.map(
      (categ, categIndex) => {
        return(
          categ.items.map(
              (item, itemIndex) => {
                  itemNames.push(
                    {
                      text: item.name[this.state.selectedLanguage],
                      itemId: itemIndex,
                      categId: categIndex
                    }
                  )
                  return true
              }
          )
        )
      }
    )
    return itemNames
  }
  getHighlightedText = (text, highlight, style = null) => {
    let highlightStyle = {
      fontWeight: 'bold',
      background: 'yellow',
      color: 'black'
    }
    if(style != null) highlightStyle = style
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={this.replaceCharacters(part.toUpperCase()) === this.replaceCharacters(highlight.toUpperCase()) ? highlightStyle : {} }>
            { part }
        </span>)
    } </span>;
  }
  setItemsToSubmit = (items) => {
    this.setState({
      itemsToSubmit: items
    })
  }
  setInvoiceInfo = (e) => {
    this.setState({
      invoiceInfo: e
    })
    localStorage['invoiceInfo'] = JSON.stringify(e)
  }
  setDeliveryInfo = (e) => {
    this.setState({
      deliveryInfo: e
    })
    localStorage['deliveryInfo'] = JSON.stringify(e)
  }
  setUserInfo = async (invoiceInfo, deliveryInfo) => {
    await this.setDeliveryInfo(deliveryInfo)
    await this.setInvoiceInfo(invoiceInfo)
    this.setState({
      showCheckout: true
    })
  }
  setCheckoutVisible = (e) => {
    this.setState({
      showCheckout: e
    })
  }
  refreshAvailableItems = async (uuid) => {
    try {
      const endpoint = this.state.endpoint
      const response = await axios(endpoint+'/api/v1/order/getItemTree', {
        method: 'POST',
        data: JSON.stringify({
          locationUUID: uuid,
          dataNeeded: { availableItems: 1 }
        })
      })
      //console.log(response)
      const data = response.data
      this.setAvailableItems(data.availableItems);
    } catch (error) {
      console.error(error);
    }
  }
  setOrderItemCount = async (categId, itemId, count) => {
    let newItem = cloneDeep(this.state.orderItemTree[categId].items[itemId])
    newItem.count = count
    let newItemTree = cloneDeep(this.state.orderItemTree)
    newItemTree[categId].items[itemId] = newItem
    await this.setState({
      orderItemTree: newItemTree
    })
    this.saveOrderItemTree()
    this.calcSelectedItemsCount()
  }
  calcTotalPrice = (items) => {
    let totalPrice = 0;
    for (let index = 0; index < items.length; index++) {
        if(!items[index].notAvailable) totalPrice += items[index].count*items[index].price;
    }
    return totalPrice;
  }
  calcSelectedItemsCount = () => {
    let count = 0
    if(this.state.orderItemTree !== null) {
      for(let i = 0; i < this.state.orderItemTree.length; i++) {
        for(let j = 0; j < this.state.orderItemTree[i].items.length; j++) {
          if(this.state.orderItemTree[i].items[j].count > 0) count += this.state.orderItemTree[i].items[j].count
        }
      }
      this.setState({
        selectedItemCount: count
      })
    }
    localStorage['selectedItemCount'] = count
  }
  setSelectedItemDetails = async (categId, itemId) => {
    let itemTree = await cloneDeep(this.state.orderItemTree);
    let item = await cloneDeep(this.state.orderItemTree[categId].items[itemId]);
    let newItem = merge(item, item.custom_values[item.default_size])

    newItem.selectedItemSize = newItem.default_size;
    newItem.selectedItemSizeName = this.getItemSizeName(this.state.itemSizes, newItem.default_size);
    itemTree[categId].items[itemId] = newItem
    
    this.setState({
      orderItemTree: itemTree
    })
    this.saveOrderItemTree()
  }
  getItemSizeName = (itemSizes, selectedSizeUuid) =>{
    for(let i=0; i < itemSizes.length; i++) {
      if(itemSizes[i].uuid === selectedSizeUuid) return((itemSizes[i].name));
    }
  }
  setSelectedLanguage = (e) => {
    this.setState({
      selectedLanguage: e
    })
    localStorage['selectedLanguage'] = e
  }
  setDictionary = (value) => {
    /*const map = value.reduce((obj, item) => ({
      ...obj,
      [item['trans_key']]: JSON.parse(item['translation']),
    }), {});*/
    this.setState({
      dictionary: value
    })
  }
  setSystemAttributes = (value) => {
    /*const map = value.reduce((obj, item) => ({
      ...obj,
      [item['trans_key']]: JSON.parse(item['attributes']),
    }), {});*/
    this.setState({
      sysAttributes: value
    })
  }
  setAssets = (value) => {
    const map = value.reduce((obj, item) => ({
      ...obj,
      [item['uuid']]: (item['path'] === null ? 'assets/fruit' : item['path']) + '/' + item['uuid'] + '.' + item['extension'],
    }), {});
    this.setState({
      assets: map
    })
  }
  setCurrencies = (value) => {
    const map = value.reduce((obj, item) => ({
      ...obj,
      [item['code']]: JSON.parse(item['name']),
    }), {});
    this.setState({
      currencies: map
    })
  }
  setLanguages = (value) => {
    const map = value.reduce((obj, item) => ({
      ...obj,
      [item['code']]: {name: item['name'], short_name: item['short_name']},
    }), {});
    this.setState({
      languages: map
    })
  }
  setDeliveryType = (type) => {
    this.setState({
      deliveryType: type
    })
    localStorage['deliveryType'] = type
  }
  setPaymentType = (type) => {
    this.setState({
      paymentType: type
    })
    localStorage['paymentType'] = type
  }
  getSystemData = async () => {
    try {
      const response = await axios(this.state.endpoint+'/api/v1/order/getItemTree', {
        method: 'POST',
        data: JSON.stringify({
          locationUUID: "",
          dataNeeded: { assets: 1, languages: 1, currencies: 1 }
        })
      })
      const data = response.data
      this.setAssets(data.assets);
      this.setCurrencies(data.currencies);
      this.setLanguages(data.languages);
    } catch (error) {
      console.error(error);
    }
    try {
      const dictionaryResponse = await axios(this.state.endpoint+'/api/v1/dictionary/webshop', {
        method: 'GET',
      })
      this.setDictionary(dictionaryResponse.data);
      this.setSystemAttributes(dictionaryResponse.data);
    } catch (error) {
      console.error(error);
    }
  }
  getItemTree = async (uuid = null) => {
    let locationUUID = ""
    if(uuid !== null) locationUUID = uuid
    try {
      const endpoint = this.state.endpoint
      const response = await axios(endpoint+'/api/v1/order/getItemTree', {
        method: 'POST',
        data: JSON.stringify({
          locationUUID: locationUUID,
          dataNeeded: { sizes: 1, categories: 1, availableItems: 1, items: 1, modifiers: 1 }
        })
      })
      const data = response.data
      this.setItemTree(data)
      this.state.setAvailableItems(data.availableItems)
    } catch (error) {
      console.error(error);
    }
  }
  setItemTree = (value) =>{
    this.setState({
      itemTree: value.categories,
      itemSizes: value.sizes,
    });
  }
  replaceCharacters = (string) => {
    let newString
    newString = string
    .replace(/Á/g,'A')
    .replace(/É/g,'E')
    .replace(/Í/g,'I')
    .replace(/Ó/g,'O')
    .replace(/Ö/g,'O')
    .replace(/Ő/g,'O')
    .replace(/Ú/g,'U')
    .replace(/Ü/g,'U')
    .replace(/Ű/g,'U')
    return newString
  }
  setSearchedItems = async (searchString) => {
    let orderItemTree = await cloneDeep(this.state.orderItemTree)
    
    if(orderItemTree !== null) {
      let count = 0
      orderItemTree.map(
        (categ, categIndex) => {
            return(
                categ.items.map(
                    (item, itemIndex) => {
                        let itemName = JSON.parse(item.name)[this.state.selectedLanguage]
                        let upperItemName = itemName.toUpperCase()
                        upperItemName = this.replaceCharacters(upperItemName)
                        let upperSearchString = searchString.toUpperCase()
                        upperSearchString = this.replaceCharacters(upperSearchString)
                        if(upperItemName.includes(upperSearchString)){
                          item.hidden = false
                          count += 1
                        } else item.hidden = true
                        return null
                    }
                )
            )
        }
      )
      
      this.setState({
        orderItemTree: orderItemTree
      })
      this.saveOrderItemTree()
      return count
    } else return 0    
  }
  saveOrderItemTree = () => {
    localStorage['orderItemTree'] = JSON.stringify(this.state.orderItemTree)
  }
  loadOrderItemTree = async () => {
    if(localStorage['orderItemTree'] !== undefined && localStorage['orderItemTree'] !== "null") {
      let orderItemTree = JSON.parse(localStorage['orderItemTree'])
      //console.log(orderItemTree)
      let orderUUID
      if(localStorage['orderUUID'] === undefined) {
        orderUUID = OrderedUUID.generate()
        localStorage['orderUUID'] = orderUUID
      } else {
        orderUUID = localStorage['orderUUID']
      }
      if(orderItemTree.length > 0) {
        await this.setState({
          orderItemTree: orderItemTree,
          orderUUID: orderUUID
        })
      }
    }
  }
  setOrderItemTree = async () => {
    if(localStorage['orderItemTree'] === "null" || localStorage['orderItemTree'] === undefined) {
      let itemTree = await cloneDeep(this.state.itemTree);
      let orderUUID
      if(localStorage['orderUUID'] === undefined) {
        orderUUID = OrderedUUID.generate()
        localStorage['orderUUID'] = orderUUID
      } else {
        orderUUID = localStorage['orderUUID']
      }
      //console.log(itemTree, orderUUID)
      try {
        await this.setState({
          orderItemTree: itemTree,
          orderUUID: orderUUID
        })
        this.saveOrderItemTree()
      } catch (error) {
        console.error(error);
      }
    } else {
      this.loadOrderItemTree()
    }
  }
  submitOrder = async () => {
    //console.log(localStorage.getItem("invoiceInfo"))

    let order = {
      locationUUID: localStorage['selectedLocationUUID'],
      uuid: localStorage['orderUUID'],
      orderNotes: "",
      price: this.calcTotalPrice(this.state.itemsToSubmit),
      deliveryCost: this.state.deliveryCost,
      userUUID: this.state.userData === null ? "" : this.state.userData.uuid,
      deviceUUID: "",
      invoiceNeeded: (localStorage.getItem("invoiceNeeded") === "true") ? 1 : 0,
      invoiceInfo: "",
      selectedTakeAway: 0,
      selectedLanguage: this.state.selectedLanguage,
      basket: this.state.itemsToSubmit,
      readyTime: ((+ new Date())/1000) + 2,
      orderIdentifier: (this.state.userData !== null && this.state.userData.first_name !== undefined) ? this.state.userData.first_name : "",
      vipID: (this.state.userData !== null && this.state.userData.vip_id !== undefined) ? this.state.userData.vip_id : "",
      deliveryType: this.state.deliveryType,
      paymentType: this.state.paymentType,
      deliveryInfo: JSON.parse(localStorage["deliveryInfo"]), 
      guestEmail: localStorage['userEmail']
    }
    if(localStorage['invoiceNeeded'] === "true") {
      order.invoiceInfo = JSON.parse(localStorage['invoiceInfo'])
      order.invoiceInfo.taxType = localStorage['taxType']
    }
    if(localStorage['taxType'] === "HAS_TAX_NUMBER" && localStorage['companyInfoByTaxNumber'] !== undefined) order.invoiceInfo = JSON.parse(localStorage['companyInfoByTaxNumber'])
    console.log(order)
    try {
      const endpoint = this.state.endpoint
      const response = await axios(endpoint+'/api/v1/order/submitOrder', {
        method: 'POST',
        data: JSON.stringify({order: order})
      })
      let data = response.data
      //console.log(response);
      localStorage['lastOrderUUID'] = this.state.orderUUID
      this.resetOrderItemtree();
      return data;
      
    } catch (error) {
      console.error(error);
      return 0;
    }
  }
  checkOrderPayment = async (orderRef) => {
    try {
      const endpoint = this.state.endpoint
      const response = await axios(endpoint+'/api/v1/payment/ios', {
        method: "post",
        data: JSON.stringify({order_ref: orderRef})
      })
      return response.data.Order
      //this.resetAppState();
    } catch (error) {
      console.error(error)
      return 0
    }
  }
  getDeliveryCost = async (weight) => {
    try {
      const endpoint = this.state.endpoint
      const response = await axios(endpoint+'/api/v1/delivery/cost', {
        method: "post",
        data: JSON.stringify({weight: weight})
      })
      return response.data
    } catch (error) {
      console.error(error)
      return 0
    }
  } 
  setDeliveryCost = (cost) => {
    this.setState({
      deliveryCost: cost
    })
  }
  resetOrderItemtree = async () => {
    await this.setState({
      orderItemTree: null
    })
    localStorage.removeItem('orderUUID')
    localStorage.removeItem('orderItemTree')
    await this.setOrderItemTree()
    await this.calcSelectedItemsCount()
  }
  setAvailableItems = (value) =>{
    this.setState({availableItems: value})
  }
  getLocations = async () => {
    
    const response = await axios(this.state.endpoint+'/api/v1/order/getOrderLocations/', {
      method: 'POST'
    })
    const data = response.data
    
    this.setLocations(data)
  }
  setLocations = (value) => {
    const map = value.reduce((obj, item) => ({
      ...obj,
      [item['uuid']]: {...item},
    }), {});
    this.setState({
      locations: map
    })
  }
  setInitData = async () => {
    await this.getLocations()
    let uuid = localStorage['selectedLocationUUID']
    if(uuid === undefined || uuid === null) {
      Object.keys(this.state.locations).forEach(locationUUID => {
        if(this.state.locations[locationUUID].default === 1) {
          uuid = this.state.locations[locationUUID].uuid
        }
      })
      if(uuid === undefined) {
        Object.keys(this.state.locations).forEach(locationUUID => {
          if(this.state.locations[locationUUID].enabled === 1) {
            uuid = this.state.locations[locationUUID].uuid
          }
        })
      }
    }
    await this.setLocationUUID(uuid)
    await this.getSystemData()
    await this.getItemTree()
    await this.setOrderItemTree()
    await this.refreshAvailableItems(uuid)
  }
  setLocationUUID = (uuid) => {
    this.setState({
      selectedLocationUUID: uuid
    })
    localStorage['selectedLocationUUID'] = uuid
  }
  componentDidMount() {
    
    this.loginWithToken()
    this.setInitData()    
    localStorage['invoiceNeeded'] = true
    localStorage["invoiceDeliverySameData"] = false
    if(localStorage['taxType'] === undefined) localStorage['taxType'] = "NO_TAX_NUMBER"
  }
  render() {
    let searchVisible = this.state.searchVisible ? " searchVisible" : ""
    let style = {}
    if(this.state.backgroundImg) {
      let background = this.state.endpoint+"/assets/img/brand/background.png"
      style = {background: "url("+background+")", backgroundRepeat: "no-repeat", backgroundSize: "cover"}
    }
    
    return (
      <ApolloProvider client={client}>
        <MainContext.Provider value = {this.state}>
          <ToastContainer toastClassName="custom-toast" autoClose={1500}/>
          <ClearCache basePath={config.basename}>
            {({ isLatestVersion, emptyCacheStorage }) => {
              if(!isLatestVersion) {
                emptyCacheStorage()
                window.location.reload()
              }
              return null
            }}              
          </ClearCache>
          <MuiPickersUtilsProvider utils={MomentUtils} >
          {<div id="webshopContainer" style={style}>              
              <TopBar 
                selectedItemCount={this.state.selectedItemCount}
                onLoginStatusChange={this.onLoginStatusChange}
                client={client}
                endpoint={this.state.endpoint}
                setLang={this.setSelectedLanguage}
                orderItemTree={this.state.orderItemTree}
                isItemVisible={this.isItemVisible}
                selectedLanguage={this.state.selectedLanguage}
                dictionary={this.state.dictionary}
                assets={this.state.assets}
                currencies={this.state.currencies}
                resetOrderItemtree={this.resetOrderItemtree}
                setOrderItemCount={this.setOrderItemCount}
                setItemsToSubmit={this.setItemsToSubmit}
                validateUserPin={this.state.validateUserPin}
                generateUserPin={this.state.generateUserPin}
                userLoggedIn={this.state.loggedIn}
                userLoggedOut={this.state.userLoggedOut}
                userLoginWithCredentials={this.userLoginWithCredentials}
                getDeliveryCost={this.getDeliveryCost}
                itemView={this.state.itemView}
              />
              <div className="header">
                <NavLink to="/">
                    <img src={this.state.endpoint+"/assets/img/brand/logo.png"} alt="brandLogo" />
                </NavLink>
                {this.state.searchVisible && <Categories 
                  orderItemTree={this.state.orderItemTree}
                  selectedLanguage={this.state.selectedLanguage}
                  assets={this.state.assets}
                  endpoint={this.state.endpoint}
                  minimized={true}
                  searchBar={config.search}
                  setSearchedItems={this.setSearchedItems}
                  getItemNames={this.getItemNames}
                  replaceCharacters={this.replaceCharacters}
                  getHighlightedText={this.getHighlightedText}
                />}
              </div>
              <div className={"contentContainer"+searchVisible}>
                <Switch>
                  <Route path={"/profile"} component={Profile} />
                  <Route path={"/"} component={Order} />
                </Switch>
                <Footer delivery={this.state.deliveryConfig} endpoint={this.state.endpoint}/>
              </div>   
              <div className="version" style={{zIndex: "9999", position: "absolute", bottom: "3rem", right: "8px"}}>v{pack.version}</div>
          </div>}
          </MuiPickersUtilsProvider>
        </MainContext.Provider>
      </ApolloProvider>
    )
  }
}

export default withRouter(Webshop);
