import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import { FadeLoader } from 'react-spinners'
import axios from 'axios'
import { toast } from 'react-toastify'
//import * as Yup from "yup";

class HasTaxNumber extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        taxNumber: "",
        companyInfo: null,
        loading: false
    };
  }
  getCompanyDataByTaxNumber = async (taxNumber) => {
    this.setState({ loading: true })
    let response = await axios(`${this.props.endpoint}/api/v1/taxpayer/${taxNumber}`)
    this.setState({ loading: false })
    console.log(response.data)
    return(response.data)
    //return({"country_code":"hu","city":"Budapest, XIX.","zip":"1191","street":"Akácfa utca","streetNumber":"1","floor":""})
  }
  notify = () => toast.warn("Hibás adószám!")
  setAddressByTaxNumber = async (taxNumber) => {
    if(taxNumber.length < 9) {
        if(taxNumber.length === 8) {
          let response = await this.getCompanyDataByTaxNumber(taxNumber)
          if(response.success) {
            let companyInfo = {}
            companyInfo.city = response.data.city
            companyInfo.country_code = response.data.countryCode
            companyInfo.floor = response.data.floor
            companyInfo.name = response.data.name
            companyInfo.street = response.data.street
            companyInfo.street_number = response.data.streetNumber
            companyInfo.zip = response.data.zip
            companyInfo.tax = taxNumber
            companyInfo.taxType = localStorage['taxType']
            await this.setState({ taxNumber, companyInfo })
            localStorage['companyInfoByTaxNumber'] = JSON.stringify(companyInfo)
            this.props.onChange(({target: {name: this.props.name, value: this.state.taxNumber}}))
            return
          }
          this.setState({ taxNumber })
          this.notify()
        } else {
            await this.setState({ 
              taxNumber,
              companyInfo: null
            })
            localStorage['companyInfoByTaxNumber'] = ""
        }
    }        
  }
  
  onChangeValue = (e) => {
    if(e.target !== undefined) {
      //let name = e.target.name
      let value = e.target.value
      this.setAddressByTaxNumber(value)
    }    
  };
  sortPropsToState = () => {
    if(this.props.value === undefined || this.props.value === "" || this.props.value === "null") return null
    for (let [key, value ] of Object.entries(JSON.parse(this.props.value))) {
      this.setState({ [key]: value });
    }
  }

  componentDidMount = () => {
    this.sortPropsToState()
  }

  render() {
    return (
      <React.Fragment>
        <TextField
            id="invoiceTax"
            className="tax"
            name="invoiceTax"
            label={this.props.dictionary['app_order_vat'].translation[this.props.selectedLanguage]}
            value={this.state.taxNumber}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            type="number"
            variant="filled"
            hidden={this.props.hidden}
        />
        {
          this.state.taxNumber !== "" && this.state.loading && <div className="loading"><FadeLoader /></div>
        }
        {
        localStorage['taxType'] === "HAS_TAX_NUMBER" && !this.state.loading && this.state.companyInfo != null && 
        <div className="companyInfo">
          <div className="block">{this.state.companyInfo.name}</div>
          <div className="block">{this.state.companyInfo.zip}</div>
          <div className="block">{this.state.companyInfo.city}</div>
          <div className="block">{`${this.state.companyInfo.street} ${this.state.companyInfo.street_number}`}</div>
        </div>
        }
      </React.Fragment>
    );
  }
}

export default HasTaxNumber;
