import React, { Component } from 'react'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary  from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails  from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import TextField from '@material-ui/core/TextField'
import InvoiceAddress from '../../../components/Profile/InvoiceAddress'
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import HasTaxNumber from '../../../components/Profile/HasTaxNumber'

class InvoiceDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded,
      taxType: localStorage['taxType']
    }
  }
  setExpanded = (e) => {
    this.setState({
      expanded: e
    })
  }
  handlePanelChange = panel => (event, isExpanded) => {
    this.setExpanded(isExpanded)
  }
  handleTaxType = (event, option) => {
    //console.log(option)
    if(option != null) {
      this.setState({ taxType: option })
      localStorage['taxType'] = option
    }
  }
  componentDidUpdate(oldProps) {
    if(oldProps.expanded !== this.props.expanded) {
      this.setExpanded(this.props.expanded)
    }
  }
  render() {
    return (
      <MuiExpansionPanel 
        square={true}
        className={"hidden appear"} 
        expanded={this.state.expanded}
        onChange={this.handlePanelChange('panel1')}
      >
        <MuiExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color={"inherit"}/>}
          aria-controls={"panel-a-content"}
          id={"panel-a-header"}
        >       
          {this.props.dictionary['app_order_invoiceDetails'].translation[this.props.selectedLanguage]}
        </MuiExpansionPanelSummary>
        <MuiExpansionPanelDetails>
          <ToggleButtonGroup
            value={this.state.taxType}
            exclusive
            onChange={this.handleTaxType}
            aria-label="text deliveryOption"
          >
            <ToggleButton 
              value="NO_TAX_NUMBER" 
              aria-label="centered"
            >
              Természetes személy
            </ToggleButton>
            <ToggleButton 
              value="HAS_TAX_NUMBER" 
              aria-label="centered"
            >
              Jogi személy
            </ToggleButton>
            <ToggleButton 
              value="FOREIGN" 
              aria-label="centered"
            >
              Külföldi jogi személy
            </ToggleButton>
          </ToggleButtonGroup>
          <HasTaxNumber
            id={"invoiceTax"}
            name={"invoiceTax"}
            value={this.props.values.invoiceTax}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            dictionary={this.props.dictionary}
            selectedLanguage={this.props.selectedLanguage}
            endpoint={this.props.endpoint}   
            hidden={this.state.taxType !== "HAS_TAX_NUMBER"}   
          />
          <InvoiceAddress
            id={"invoiceAddress"}
            name={"invoiceAddress"}
            value={this.props.values.invoiceAddress}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            dictionary={this.props.dictionary}
            selectedLanguage={this.props.selectedLanguage}
            endpoint={this.props.endpoint}   
            hidden={this.state.taxType === "HAS_TAX_NUMBER"}   
          />
        </MuiExpansionPanelDetails>
      </MuiExpansionPanel>
    )
  }
}
export default InvoiceDetails